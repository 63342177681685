import React, { useState, useEffect } from 'react';
import Background from '../../assets/fundo.png';
import styles from './styles';

const Home = () => {
    const [progress, setProgress] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [apiResponse, setApiResponse] = useState(null);

    function generate() {
        return 'event_' + Date.now();
    }

    /* global fbq */

    useEffect(() => {
        const getPathname = () => {
            const path = window.location.pathname;
            return path.substring(1) || 'redirect';
        };

        const source = getPathname();

        if (typeof fbq === 'function') {
            var eventId = generate();
            fbq('track', source, { eventID: eventId });
            sendEvent(source, eventId);
        }

        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    setLoadingMessage('✅ Concluído!');

                    setTimeout(() => {
                        window.location.replace(`https://wlsuperbet.adsrv.eacdn.com/C.ashx?btag=a_5800b_378c_&affid=678&siteid=5800&adid=378&c=${source}`);
                    }, 100);

                    return 100;
                }
                const diff = 100 / 40;
                const newProgress = Math.min(oldProgress + diff, 100);
                updateLoadingMessage(newProgress);
                return newProgress;
            });
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const updateLoadingMessage = (progress) => {
        if (progress < 40.33) {
            setLoadingMessage('⏳ Carregando página...');
        } else if (progress < 72.66) {
            setLoadingMessage('🔓 Conexão segura...');
        } else if (progress < 85) {
            setLoadingMessage('✅ Concluído!');
        }
    };

    const sendEvent = (source, eventId) => {
        const payload = {
            data: [
                {
                    event_name: source,
                    event_time: new Date().toISOString(),
                    user_data: {
                        em: 'viraw@gmail.com',
                        ph: ''
                    }
                }
            ],
            partner_agent: 'trackingViraw'
        };

        fetch('https://api-tracking-2vpd.onrender.com/process-payload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                setApiResponse(data);
            })
            .catch(error => {
                console.error('Error sending event:', error);
                setApiResponse({ error: 'Failed to send event', details: error.message });
            });
    };

    return (
        <div style={styles.backgroundContainer}>
            <img src={Background} alt="Background" style={styles.backgroundImage} />
            <div style={styles.progressContainer}>
                <div className="loading-message" style={styles.loadingMessage}>{loadingMessage}</div>
                <div style={styles.progressBarContainer}>
                    <div style={{ ...styles.progressBar, width: `${progress}%` }} />
                </div>
               
            </div>
        </div>
    );
};

export default Home;
